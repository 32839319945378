import React from 'react'

function Intro() {
  return (
    <section id="intro" class="bg-intro">
        <div class="row g-0">
            <div class="col-12">
                <h2 className="intro-title">Unlock your understanding, chunk by chunk!</h2>
                <h4 className="intro-subheading">Fun games, learning resources and downloadable study materials!</h4>
            </div>
        </div>
        <div className="row g-0 intro-container pb-5">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-4 pb-5">
                    <div class="col-12">
                        <div class="card h-100">
                            <img src={require("../assets/images/maths_games.jpg")} alt="a crocodile in a matrix" className="card-img-top"></img>
                            <div class="card-body">
                                <h5 class="card-title"><a href="#" class="stretched-link">Maths Games</a></h5>
                                <p class="card-text">Fun games to reinforce basic maths concepts for a range of levels and ages.</p>
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">COMING SOON</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card h-100">
                            <img src={require("../assets/images/science_game.jpg")} alt="female scientist with test tubes" className="card-img-top"></img>
                            <div class="card-body">
                                <h5 class="card-title"><a href="#" class="stretched-link">Science Games</a></h5>
                                <p class="card-text">Fun games to reinforce basic concepts for a range of levels and ages.</p>
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">COMING SOON</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card h-100">
                            <img src={require("../assets/images/learning_resources.jpg")} alt="books and colouring pencils" className="card-img-top"></img>
                            <div class="card-body">
                                <h5 class="card-title"><a href="#" class="stretched-link">Other Learning Resources</a></h5>
                                <p class="card-text">Printable resources and fun ideas for learning.</p>
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">COMING SOON</small>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>
  )
}

export default Intro