import React from 'react'

function Testimonials() {
  return (
    <section id="testimonials">
        <div class="row g-0 bg-testimonials py-5 quote text-center">
            {/*<div class="d-none d-lg-block col-12 col-lg-4 offset-lg-2 p-5">
                <h5>"My kids love these games and they are now so much more confident in maths"</h5>
            </div>*/}
            <div class="col-12 col-lg-6 offset-lg-3 p-5">
                <h5>"Sarah is a great teacher and very patient. Her learning materials have helped me to consolidate the basics and move on to more difficult topics."</h5>
            </div>
        </div>
    </section>
  )
}

export default Testimonials