import React from "react";
import Navbar from "./components/Navbar";
import Socials from "./components/Socials";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Resources from "./pages/resources";
import Tutoring from "./pages/tutoring";
import Contact from "./pages/contact";
import './App.css';
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
        <Navbar />
        <Socials />
        <ScrollToTop>
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route
                  path="/contact"
                  element={<Contact />}
              />
              {/*<Route path="/resources" element={<Resources />} />*/}
              {/*<Route path="/tutoring" element={<Tutoring />} />*/}
          </Routes>
          </ScrollToTop>
        <Footer />
    </Router>
  );
}

export default App;
