import React from "react";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Testimonials from "../components/Testimonials";
import DownloadBook from "../components/DownloadBook";

 
const Home = () => {
    return (
        <div className="navbar-allowance">
            <Hero />
            <Intro />
            <Testimonials />
            <DownloadBook />
        </div>
    );
};
 
export default Home;