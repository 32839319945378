import React from 'react'

function WhoWeAre() {
  return (
    <section id="whoweare" class="bg-about">
        <div class="row g-0">
            <div class="col-12 col-md-6 offset-md-3 who-text px-3">
                <p>My name is Sarah and I have been tutoring maths and physics for over 10 years, working with groups and individuals over a range of ages and abilities both in private settings 
                  and in college. My own children (aged 7-14) are home educated and we have spent time moulding an education to suit their individual needs. I am setting up Study Chunks 
                  to offer an easy access, affordable set of resources to help students and families with learning. We are currently focusing on maths and science content but as we 
                  grow, I hope to build resources for a wider range of subjects.
                </p>
                <br></br>
                <p>Over the years it's become apparent that many students struggle with their GCSE and A-Level work because often they don't have a solid foundation to build on. Our aim is to 
                  break down larger topics into fun and more manageable chunks so students can firm up on the basics before moving onto more difficult material.
                </p>
                <br></br>
                <p>School curriculums aren't always the most fun to work through but often it is through play that we learn and absorb the most! We hope to inspire, encourage curiosity and instil 
                  a passion for learning through mini games and fun learning resources which support the school curriculum and more!
                </p>
            </div>
        </div>
    </section>
  )
}

export default WhoWeAre