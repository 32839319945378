import React from 'react'

function Hero() {
  return (
    <section id="hero" class="bg-hero">
        <div class="row g-0 hero-text">
            <div class="col-12">
                <img
                    className="hero-pic"
                    alt="multi-coloured puzzle pieces"
                    src={require("../assets/images/contact_puzzle.jpg")}
                /> 
            </div>
            <div class="col-12 text-on-image">
                <h2><strong>Empowering and Inspiring Learners</strong></h2>
            </div>
        </div>
    </section>
  )
}

export default Hero
