import React from 'react'

function Team() {
  return (
    <section id="team" class="bg-team">
        <div class="row g-0 d-flex align-items-center">
            <div class="col-12 col-md-6 offset-md-3 team-info px-3">
                <h3 class="py-5">The Team</h3>
                <div class="row g-0">
                    <div className='col-12 col-lg-4'>
                    <img
                        className="team-pic"
                        alt="tutor Sarah"
                        src={require("../assets/images/team_sarah.png")}
                    />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h5 className="team-name">Sarah</h5>
                        <br></br>
                        <div class="table-container">
                            <table className='team-table'>
                                <tr>
                                <th>Background:</th>
                                <td>Engineering, Psychology, Teaching</td>
                                </tr>
                                <tr>
                                <th>Interests:</th>
                                <td>Music, Art, Coding, Motorsport</td>
                                </tr>
                                <tr>
                                <th>Role:</th>
                                <td>Game maker at Study Chunks</td>
                                </tr>
                            </table>
                        </div>

                        <p className="py-4 team-text">I have always loved maths and problem solving, studying engineering at university.  After my children were born, I became fascinated by child development and 
                            education.  I completed a psychology degree, worked in a number of settings and as a private tutor, eventually choosing to home educate my own children. Every individual has their own learning style 
                            but play seems to be a universal learning tool.  Play can be a fantastically effective way for us to absorb so much information! It is my aim to create some simple bite-size games to help reinforce 
                            basic maths and science concepts that are fundamental to further study.</p>
                    </div>
                </div>
                {/*<br></br>
                <div class="row g-0 pb-5">
                    <div className='col-12 col-lg-4'>
                    <img
                        className="team-pic"
                        alt="tutor Sarah"
                        src={require("../assets/images/team_su.png")}
                    />
                    </div>
                    <div className="col-12 col-lg-8">
                        <h5 className="team-name">Suhmayah</h5>
                        <h6 className="team-subjects">Computer Science and Project-based Learning</h6>
                        <p className="py-4 team-text">I am a software developer and tech enthusiast! I run courses in West Africa to promote digital literacy and improve career opportunities for students. I specialise in helping develop project-based learning plans for students with a 
                        particular interest.</p>
                    </div>
                </div>*/}
            </div>
        </div>
    </section>
  )
}

export default Team